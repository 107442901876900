<template>
  <Breadcrumbs main="Dashboard" title="File Exchange"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- Introduction -->
        <div class="card card-absolute" id="introduction">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5 class="mb-0 text-white"><i class="bi bi-info-circle me-2"></i> General Information</h5>
          </div>
          <div class="card-body border border-primary rounded-3">
            <span><i class="icofont icofont-hand-right font-success">&nbsp;</i>
              Here, you can access and download <strong>files that ryd has shared with your organization.</strong>.</span>
            <br/>

            <span><i class="icofont icofont-hand-right font-success">&nbsp;</i>
              The files may contain sensitive information such as credentials, configuration details, or other partner-specific data.</span>

            <br/>
            <span><i class="icofont icofont-hand-right font-success">&nbsp;</i>
              To download a file, click the <strong>Download</strong> button next to the file you need.</span>
            <br/>
            <span><i class="icofont icofont-hand-right font-success">&nbsp;</i>
              If you need additional files or have questions about the content, please contact ryd.</span>
          </div>
        </div>

        <!-- Files List -->
        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="mb-0 text-white"><i class="bi bi-file-earmark me-2"></i> Available Files</h5>
          </div>
          <div class="card-body border border-primary rounded-3">
            <!-- Loading State -->
            <div v-if="isLoading" class="text-center py-5">
              <div class="loader-box">
                <div class="loader-17"></div>
              </div>
              <h6 class="mt-3 fade show">
                <span class="loading-text">Loading your files...</span>
              </h6>
            </div>

            <!-- Error State -->
            <div v-else-if="errorMessage" class="alert alert-danger d-flex align-items-center">
              <i class="fa fa-exclamation-circle me-2"></i>
              {{ errorMessage }}
              <button @click="fetchFiles" class="btn btn-sm btn-outline-danger ms-auto">Retry</button>
            </div>

            <!-- Empty State -->
            <div v-else-if="files.length === 0" class="text-center py-5">
              <div class="mb-3">
                <i class="fa fa-folder-open text-muted" style="font-size: 3rem;"></i>
              </div>
              <h5 class="text-muted">No files available</h5>
              <p class="text-muted">There are currently no files shared with your partner account.</p>
            </div>

            <!-- Files Table -->
            <div v-else class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead class="table-primary">
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Size</th>
                  <th>Upload Date</th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="file in files" :key="file.id">
                  <td class="fw-bold">{{ file.name }}</td>
                  <td>{{ file.description }}</td>
                  <td>{{ formatFileType(file.fileType) }}</td>
                  <td>{{ formatFileSize(file.fileSize) }}</td>
                  <td>{{ formatDate(file.uploadDate) }}</td>
                  <td>
                    <button
                        @click="downloadFile(file.id, file.name)"
                        class="btn btn-success btn-sm"
                        :class="{ 'btn-loading': downloadingFile === file.id }"
                        :disabled="downloadingFile === file.id">
                      <i class="fa fa-download me-1"></i>
                      {{ downloadingFile === file.id ? '' : 'Download' }}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <!-- Status Messages -->
            <div v-if="successMessage" class="alert alert-success mt-4 d-flex align-items-center">
              <i class="fa fa-check-circle me-2"></i>
              {{ successMessage }}
            </div>
          </div>
        </div>

        <!-- File Types Information -->
        <div class="card card-absolute shadow-sm mt-4">
          <div class="card-header bg-info text-white">
            <h5 class="mb-0"><i class="bi bi-file-earmark-text me-2"></i> File Information</h5>
          </div>
          <div class="card-body border border-primary rounded-3">
            <p>The files available for download may include:</p>
            <table class="table table-bordered table-striped">
              <thead class="table-primary">
              <tr>
                <th>File Type</th>
                <th>Description</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(desc, type) in fileTypes" :key="type">
                <td class="fw-bold">{{ type }}</td>
                <td>{{ desc }}</td>
              </tr>
              </tbody>
            </table>

            <div class="alert alert-warning mt-4">
              <i class="fa fa-exclamation-triangle me-2"></i>
              <strong>Important:</strong> Some files may contain sensitive information. Please handle them securely and do not share them outside your organization.
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { getUserAddDataFromLocalStorage } from "@/utils/userUtils";
import axios from "axios";
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL, RPP_API_SERVER_URL
} from "@/constants/constants";
import {auth} from "@/firebase/firebaseInit";


export default {
  name: "PartnerFiles",
  keywords: [
    "partner files",
    "download files",
    "secure files",
    "partner documents",
    "shared documents",
    "file download",
    "credentials",
    "secure download",
    "partner portal files",
    "company files",
    "partner shared files",
    "data access",
    "partner access",
    "document management",
    "partner documents",
    "secure content"
  ],

  data() {
    return {
      loggedInUserGroup: '',
      isLoading: false,
      files: [],
      downloadingFile: null,
      errorMessage: null,
      successMessage: null,
      fileTypes: {
        "CSV Files": "Comma-separated values files that can be opened in Excel or other spreadsheet software.",
        "PDF Documents": "Adobe Portable Document Format files for reports and documentation.",
        "Configuration Files": "Settings and parameters for system configuration.",
        "Credentials": "Secure access information and passwords for partner systems."
      }
    };
  },

  mounted() {
    this.fetchFiles();
    const { userGroup } = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
  },

  methods: {
    async fetchFiles() {
      this.isLoading = true;
      this.errorMessage = null;
      this.successMessage = null;

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('User not authenticated');
      }

      const idToken = await currentUser.getIdToken(true);

      try {
        const response = await axios.get(`${RPP_API_SERVER_URL}/partner-files`, {
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY,
            'Authorization': `Bearer ${idToken}`,
            'uid': this.loggedInUserGroup
          }
        });

        if (response.status === 200 && response.data.success) {
          this.files = response.data.files;
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error fetching partner files:', error);
        this.errorMessage = "Unable to load partner files. Please try again later.";
      } finally {
        this.isLoading = false;
      }
    },

    async downloadFile(fileId, fileName) {
      this.downloadingFile = fileId;
      this.errorMessage = null;
      this.successMessage = null;

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('User not authenticated');
      }

      const idToken = await currentUser.getIdToken(true);

      try {
        const response = await axios.get(`${RPP_API_SERVER_URL}/partner-files/download/${fileId}`, {
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY,
            'Authorization': `Bearer ${idToken}`,
            'uid': this.loggedInUserGroup
          }
        });

        if (response.status === 200 && response.data.success) {
          // Create download link
          const link = document.createElement('a');
          link.href = response.data.url;
          link.download = fileName || 'downloaded-file';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.successMessage = `Successfully started download for "${fileName}"`;
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error downloading file:', error);
        this.errorMessage = "An error occurred while downloading the file. Please try again later.";
      } finally {
        this.downloadingFile = null;
      }
    },

    formatFileType(fileType) {
      if (!fileType) return 'Unknown';

      const typeMap = {
        'text/csv': 'CSV',
        'application/pdf': 'PDF',
        'application/json': 'JSON',
        'text/plain': 'TXT',
        'application/vnd.ms-excel': 'XLS',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX'
      };

      return typeMap[fileType] || fileType.split('/')[1]?.toUpperCase() || fileType;
    },

    formatFileSize(bytes) {
      if (!bytes) return '0 B';

      const units = ['B', 'KB', 'MB', 'GB'];
      let i = 0;
      let size = bytes;

      while (size >= 1024 && i < units.length - 1) {
        size /= 1024;
        i++;
      }

      return `${size.toFixed(1)} ${units[i]}`;
    },

    formatDate(dateString) {
      if (!dateString) return 'Unknown';

      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.loading-text {
  display: inline-block;
  animation: slideIn 1s ease-out, pulse 2s infinite;
}

@keyframes slideIn {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.btn-loading {
  position: relative;
  pointer-events: none;
  color: transparent !important;
}

.btn-loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.content strong {
  color: var(--theme-deafult);
}


.table-bordered {
  border: 1px solid #ddd;
}

</style>