<template>
  <Breadcrumbs main="Integration Guide" title="ryd.pay APIs"/>

  <div class="container-fluid">
    <div class="col-sm-12 col-md-12 d-flex justify-content-center">
      <select v-model="selectedApi" @change="loadSwaggerUI" class="m-r-20 form-control border border-primary min-width-max-content">
        <option value="staging">Staging Environment (Upcoming Changes)</option>
        <option value="production">Production Environment (Current Live Version)</option>
      </select>
      <button  class="btn btn-pill btn-success btn-air-success active input-group-text" @click="downloadJson">Download API Spec in json according to openAPI 3.0.0</button>
    </div>
    <div class="swagger" id="swagger"></div>
  </div>

</template>


<script>
import swaggerui from 'swagger-ui';
//import 'swagger-ui/dist/swagger-ui.css';
import { API_SPECS } from './config/SwaggerApiVersionsConfig';


const apiOrigin = 'https://tt4.thinxcloud-staging.de';

export default {
  name: "swagger",
  keywords: [
    'API Usage', 'API Documentation', 'API Guide', 'API Specs', 'rydpay',
    'Swagger UI', 'API Reference', 'API Endpoints', 'REST API', 'API Explorer',
    'Fuelling API', 'Car Wash API', 'EV Charging API', 'API Methods', 'API Integration',
    'API Schema', 'API Models', 'HTTP Methods', 'API Requests', 'API Responses',
    'Authentication', 'Authorization', 'API Parameters', 'API Examples', 'Request Formats',
    'Response Formats', 'API Testing', 'API Debugging', 'API Simulation', 'API Playground',
    'Interactive API', 'API Console', 'API Tutorials', 'API Instructions', 'API Best Practices',
    'API Errors', 'API Status Codes', 'API Callbacks', 'API Webhooks', 'API Rate Limits',
    'API Limits', 'API Quotas', 'Versioning', 'API Updates', 'API Changes', 'API Release Notes',
    'API Versions', 'API Deprecation', 'Deprecated APIs', 'API Lifecycle', 'API Management',
    'API Monitoring', 'API Performance', 'API Security', 'API Keys', 'API Tokens', 'Staging API',
    'Production API', 'API Configuration', 'API Settings', 'API Onboarding', 'New API Features',
    'API Reference Guide', 'API Instructions', 'API Definitions', 'API Objects', 'API Collections',
    'API Documentation Portal', 'API Support', 'API FAQs', 'API Troubleshooting', 'API Connectivity',
    'API Workflow', 'API Usage Metrics', 'API Analytics', 'API Logging', 'EV Charging', 'Fuelling', 'Car Wash'
  ],
  data() {
    return {
      selectedApi: 'production',  // default selection
    }
  },
  mounted() {
    this.addNoReferrerMeta();

    // Set selectedApi based on URL fragment
    const hash = window.location.hash.replace('#/', ''); // Removing both '#' and leading '/'
    if (hash === 'production' || hash === 'staging') {
      this.selectedApi = hash;
    }

    this.loadSwaggerUI();
  },
  watch: {
    '$route' (to, from) {
      if (to.hash) {
        this.selectedApi = to.hash.replace('#/', '');
        //console.log("111", to.hash.replace('#/', ''));
        //this.selectedApi = 'staging';
        this.loadSwaggerUI();
      }
    }
  },
  methods: {
    downloadJson() {
      // Determine the file to download based on the selected API
      const spec = API_SPECS[this.selectedApi];
      const fileName = this.selectedApi === 'staging' ? 'data/staging-20250321.json' : 'data/tt4-20250321.json';
      const downloadName = this.selectedApi === 'staging' ? 'staging-api-spec-rydpay.json' : 'production-api-spec-rydpay.json';

      // Dynamically import the JSON file
      import(`./${spec.filePath}`).then((data) => {
        const jsonStr = JSON.stringify(data, null, 2); // Stringify the JSON data
        const blob = new Blob([jsonStr], {type: 'application/json'});
        const url = URL.createObjectURL(blob);

        // Create a link and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = downloadName; // Use the dynamic download name
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
    },
    addNoReferrerMeta() {
      let meta = document.createElement('meta');
      meta.name = "referrer";
      meta.content = "no-referrer";
      document.getElementsByTagName('head')[0].appendChild(meta);
    },
    loadSwaggerUI() {
      const spec = require(`./${API_SPECS[this.selectedApi].filePath}`);

      // Update the URL hash only if it's different from the current selection
      if (window.location.hash.replace('#/', '') !== this.selectedApi) {
        window.location.hash = this.selectedApi;
      }

      swaggerui({
        spec: spec,
        dom_id: '#swagger',
        displayRequestDuration: true,
        deepLinking: true,
        persistAuthorization: true,
        showMutatedRequest: true,
        requestInterceptor: (req) => {
          req.cors = true;
          req.proxy = apiOrigin;

          if (req.method === "OPTIONS") {
            req.headers["Access-Control-Request-Headers"] = "x-txn-auth-token";
          }

          req.headers['x-txn-auth-token'] = "token";

          return req;
        }
      });
    },
  },
}
</script>

<!-- define the css styling for darkmode here, not in an external scss or css file. -->
<style>
.dark-only .swagger-ui .model,
.dark-only .swagger-ui section.models span,
.dark-only .swagger-ui .info .title,
.dark-only .swagger-ui .info p,
.dark-only .swagger-ui .info li,
.dark-only .swagger-ui .opblock-tag.no-desc span {
  color: rgba(255, 255, 255, 0.6);
}
</style>
<style scoped>
@import "swagger-ui-themes/themes/3.x/theme-material.css";
</style>
  