<template>
  <Breadcrumbs main="Dashboard" title="Active Stations"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- Introduction -->
        <div class="card" id="introduction">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5 class="mb-0"><i class="bi bi-info-circle me-2"></i> General Information</h5>
          </div>
          <div class="card-body">
            <span><i class="icofont icofont-hand-right font-success">&nbsp;</i>
              You can download <strong>a list of all petrol stations</strong> currently supported by ryd.</span>
            <br/>

            <span><i class="icofont icofont-hand-right font-success">&nbsp;</i>
              Please note that EV stations are NOT included in this download. If you need EV station data, please contact the ryd team, and we’ll be happy to assist you.</span>

            <br/>
            <span><i class="icofont icofont-hand-right font-success">&nbsp;</i>
              Choose your preferred download format and click the <strong>Export Data</strong> button to download the file.</span>

          </div>
        </div>

        <!-- Export Card -->
        <div class="card">
          <div class="card-body">
            <div class="form theme-form">
              <div class="row align-items-center p-4 rounded">
                <div class="col-md-3">
                  <label class="form-label fw-bold text-dark">Export Format</label>
                </div>
                <div class="col-md-6">
                  <div class="d-flex gap-4">
                    <div class="form-check">
                      <input
                          class="form-check-input cursor-pointer primary border border-primary"
                          type="radio"
                          id="xlsx"
                          value="xlsx"
                          v-model="exportFileFormat"
                          name="exportFileFormat">
                      <label class="form-check-label cursor-pointer" for="xlsx">
                        Excel (XLSX)
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                          class="form-check-input cursor-pointer primary border border-primary"
                          type="radio"
                          id="csv"
                          value="csv"
                          v-model="exportFileFormat"
                          name="exportFileFormat">
                      <label class="form-check-label cursor-pointer" for="csv">
                        CSV Format
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-md-3">
                  <button
                      @click.prevent="handleExport"
                      class="btn btn-primary w-100"
                      :class="{ 'btn-loading': isExportStarted }"
                      :disabled="isExportStarted">
                    <i class="fa fa-download me-2"></i>
                    {{ isExportStarted ? 'Processing...' : 'Export Data' }}
                  </button>
                </div>
              </div>

              <div class="row align-items-center p-4 rounded">
                <div class="col-md-3">
                  <label class="form-label fw-bold text-dark">Include ryd’s internal POI ID in export</label>
                </div>
                <div class="col-md-6">
                  <div class="d-flex gap-4">
                    <div class="form-check">
                      <input
                          class="form-check-input cursor-pointer primary border border-primary"
                          type="checkbox"
                          id="includePoiId"
                          v-model="includePoiId"
                          name="includePoiId"
                      >
                      <label class="form-check-label cursor-pointer" for="includePoiId">
                        yes
                      </label>
                    </div>
                  </div>
                </div>

              </div>

              <!-- Loading State -->
              <div v-if="isExportStarted" class="loading-indicator mt-4">
                <h6 class="text-center mb-3 fade show">
                <span class="loading-text">
                 &#128663; Rounding up all those stations... give us a moment to herd them into a file! &#128663;
                </span>
                </h6>
                <div class="loader-box">
                  <div class="loader-17"></div>
                </div>
              </div>

              <!-- Status Messages -->
              <div v-if="exportActiveStations.errorMessage" class="alert alert-danger mt-4 d-flex align-items-center">
                <i class="fa fa-exclamation-circle me-2"></i>
                {{ exportActiveStations.errorMessage }}
              </div>
              <div v-if="exportActiveStations.successMessage"
                   class="alert alert-success mt-4 d-flex align-items-center">
                <i class="fa fa-check-circle me-2"></i>
                {{ exportActiveStations.successMessage }}
              </div>
              <div v-if="exportActiveStations.infoMessage" class="alert alert-info mt-4 d-flex align-items-center">
                <i class="fa fa-info-circle me-2"></i>
                {{ exportActiveStations.infoMessage }}
              </div>
            </div>
          </div>
        </div>

        <!-- Dataset Information -->
        <div class="card shadow-sm mt-4">
          <div class="card-header bg-info text-white">
            <h5 class="mb-0"><i class="bi bi-table me-2"></i> Dataset Information</h5>
          </div>
          <div class="card-body">
            <p>The exported file contains the following columns:</p>
            <table class="table table-bordered table-striped">
              <thead class="table-primary">
              <tr>
                <th>Field</th>
                <th>Meaning</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(desc, field) in datasetFields" :key="field">
                <td class="fw-bold">{{ field }}</td>
                <td>{{ desc }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- NUTS Information -->
        <div class="card shadow-sm mt-4">
          <div class="card-header bg-info text-white">
            <h5 class="mb-0"><i class="bi bi-globe me-2"></i> What are NUTS?</h5>
          </div>
          <div class="card-body">
            <p>The EU developed the NUTS (Nomenclature of Territorial Units for Statistics) classification to
              standardize regional statistics. Learn more at <a
                  href='https://ec.europa.eu/eurostat/web/gisco/geodata/statistical-units/territorial-units-statistics'
                  target='_blank' class='text-primary'>Eurostat NUTS</a>.</p>
            <p class="mb-2">NUTS divides each EU country into 3 levels:</p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><strong>NUTS 1:</strong> Major socio-economic regions</li>
              <li class="list-group-item"><strong>NUTS 2:</strong> Basic regions for regional policies</li>
              <li class="list-group-item"><strong>NUTS 3:</strong> Small regions for specific diagnoses</li>
            </ul>
            <br/>
            <blockquote class="blockquote border-start border-4 ps-3 text-muted">
              <p><strong>Note:</strong> The country itself is represented separately at the
                <strong>national level (NUTS-0)</strong> but is not considered part of the three NUTS levels.</p>
            </blockquote>

            <p>Here is an example:</p>
            <table class="table table-bordered table-striped">
              <thead class="table-primary">
              <tr>
                <th>Region</th>
                <th>NUTS Level</th>
                <th>NUTS Code</th>
                <th>NUTS Name (Normalized by ryd)</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in nutsData" :key="index">
                <td class="fw-bold">{{ item.region }}</td>
                <td>{{ item.nuts_level }}</td>
                <td>{{ item.nuts_code }}</td>
                <td>{{ item.nuts_name_normalized }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToTop} from "@/utils/anchorUtils";
import axios from "axios";
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL
} from "@/constants/constants";
import {getFormattedDateTime} from "@/utils/dateUtils";

export default {
  name: "ActiveStations",
  keywords: [
    "active stations",
    "export stations",
    "download stations",
    "petrol stations",
    "fuel stations",
    "rydpay stations",
    "partner stations",
    "active petrol stations",
    "station export",
    "download active stations",
    "csv export",
    "excel export",
    "station list",
    "partner data export",
    "fuel station data",
    "download fuel stations",
    "data export",
    "station insights",
    "partner stations download",
    "stations overview"
  ],

  data() {
    return {
      loggedInUserGroup: '',
      isExportStarted: false,
      exportFileFormat: 'xlsx',
      includePoiId: false,
      exportActiveStations: {
        file: "",
        rowCount: 0,
        errorMessage: null,
        successMessage: null,
        infoMessage: null
      },
      datasetFields: {
        "Station_brand": "The official brand name of the petrol station",
        "Latitude": "Geographic coordinate specifying the north-south position",
        "Longitude": "Geographic coordinate specifying the east-west position",
        "Street": "Street name of the station's location",
        "House_number": "Building number on the street",
        "City": "Municipality where the station is located",
        "Zip_code": "Postal code of the station's address",
        "Country_code": "Two-letter ISO country code (e.g., DE, AT, NL)",
        "NUTS_1_code": "The NUTS 1 code defines major socio-economic regions, like DE2 for Bayern (Bavaria) in Germany.",
        "NUTS_1_name": "The NUTS 1 name is the official name of a major socio-economic region, typically a German Bundesland (e.g., Bayern/Bavaria). ryd normalized it to UPPER case.",
        'POI_id': "This is the internal ID of a station in the ryd system."
      },
      nutsData: [
        {region: "Germany", nuts_level: "NUTS-0", nuts_code: "DE", nuts_name_normalized: "GERMANY"},
        {region: "Bayern", nuts_level: "NUTS-1", nuts_code: "DE2", nuts_name_normalized: "BAYERN"},
        {region: "Oberbayern", nuts_level: "NUTS-2", nuts_code: "DE21", nuts_name_normalized: "OBERBAYERN"},
        {region: "München", nuts_level: "NUTS-3", nuts_code: "DE212", nuts_name_normalized: "MÜNCHEN"}
      ]

    };
  },

  mounted() {
    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
  },

  watch: {
    exportFileFormat(newVal) {
      console.log("exportFileFormat changed to:", newVal);
    },
    includePoiId(newVal) {
      console.log("includePoiId changed to:", newVal);
    }
  },

  methods: {
    scrollToCard,
    scrollToTop,
    getFormattedDateTime,

    async handleExport() {

      console.log("Export File Format:", this.exportFileFormat);
      console.log("Include POI ID:", this.includePoiId);

      this.isExportStarted = true;
      this.exportActiveStations = {
        file: "",
        rowCount: 0,
        errorMessage: null,
        successMessage: null,
        infoMessage: null
      };

      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/active-gas-stations`, {
          params: {
            format: this.exportFileFormat,
            includePoiId: this.includePoiId
          },
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserGroup
          }
        });

        if (response.status === 200 && response.data) {
          const {file, rowCount} = response.data;

          if (rowCount === 0) {
            this.exportActiveStations.infoMessage = "No active stations could be found.";
            return;
          }

          // Convert base64 to blob
          const byteCharacters = atob(file);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);

          const contentType = this.exportFileFormat === 'csv'
              ? 'text/csv'
              : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

          const blob = new Blob([byteArray], {type: contentType});

          // Create download link
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          const fileName = `active_petrol_stations_${getFormattedDateTime()}.${this.exportFileFormat}`;

          link.href = url;
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);

          this.exportActiveStations.successMessage = `Successfully exported ${rowCount.toLocaleString()} stations to ${fileName}`;
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error exporting active stations:', error);
        this.exportActiveStations.errorMessage = "A technical error occurred while generating the export.";
      } finally {
        this.isExportStarted = false;
      }
    },
  }
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.loading-text {
  display: inline-block;
  animation: slideIn 1s ease-out, pulse 2s infinite;
}

@keyframes slideIn {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.btn-loading {
  position: relative;
  pointer-events: none;
}

.btn-loading::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.content strong {
  color: var(--theme-deafult);
}

.card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #ddd;
}

.card {
  border-radius: 10px;
  overflow: hidden;
}

.table-bordered {
  border: 1px solid #ddd;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  font-weight: bold;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.list-group-item {
  border: none;
}
</style>