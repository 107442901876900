<template>
  <Breadcrumbs main="rydpay APIs" title="Order State Management"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">Index of Content</div>

            <p>
              On this page, you will find detailed information about the Ryd order state machine, its transitions, and
              the specifics of each state.
              The topics covered include:
            </p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('order-state-machine-overview')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Order State Machine Overview</a>
                An introduction to the order state machine and its purpose.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('state-transitions')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Order State Transition Diagrams</a>
                Visual representations of different payment flows and their state transitions.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('state-transitions')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> State Machine Types by Product/Service</a>
                Explanation of which state machine types are used by different products/services.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('detailed-state-descriptions')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Detailed State Description
                </a>
                Comprehensive descriptions of each state within the order state machine.
              </li>


            </ul>

          </div>
        </div>


        <div class="card" id="order-state-machine-overview">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Order State Machine Overview</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            The ryd API utilizes a comprehensive order state machine to manage transactions efficiently and accurately.
            This state machine ensures that every transaction progresses through a well-defined series of states, from
            creation to completion.

            By adhering to this structured approach, ryd guarantees the integrity and consistency of transaction
            processing across different services, including fuelling, car wash, and EV charging.

            <br/> <br/>
            The state machine is designed to handle various transaction scenarios, including successful completions,
            errors, and retries. Each state within the machine represents a specific phase in the transaction lifecycle,
            and the transitions between these states are governed by predefined rules and conditions.
            <br/> <br/>
            There are two primary types of transactions managed by the Ryd order state machine: Prepay and Postpay. Each
            type has its own set of states and transitions to accommodate different payment workflows. The type used for
            each product/service (fuelling, car wash, and EV charging) is determined by the petrol stations or specific
            circumstances of the product/service.
            For more details, see <a
              href="/integration-guide/rydpay-apis/order-state-management#state-machine-types-by-product">State Machine
            Types By Product/Service</a>.
          </div>
        </div>


        <div class="card" id="state-transitions">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Order State Transition Diagrams</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>

          <div class="card-body">
            <p class="m-b-30">
              The following diagrams illustrate the state transitions for different payment flows in the ryd order state machine.
            </p>

            <!-- Improved "How to read these diagrams" section -->
            <div class="card card-absolute shadow-sm border-0 mb-4">
              <div class="card-header bg-info-subtle">
                <h5><i class="fa fa-map"></i> How to Read State Diagrams</h5>
              </div>
              <div class="card-body p-4 border border-secondary rounded-3">
                <div class="alert alert-light rounded-3 shadow-sm">
                  <div class="mb-3">
                    <h6 class="text-primary">
                      <span class="badge bg-primary me-2">1</span> <span class="font-dark">Transitions</span>
                    </h6>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item border-0 ps-3">
                        <i class="fa fa-exchange"></i> Each transition represents a specific action or event that causes the order to move from one state to another.
                      </li>
                    </ul>
                  </div>

                  <div class="mb-3">
                    <h6 class="text-success">
                      <span class="badge bg-success me-2">2</span> Success States
                    </h6>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item border-0 ps-3">
                        <i class="fa fa-check-circle text-success"></i> <span class="text-success">Green states</span> indicate successful completion of the order process.
                      </li>
                      <li class="list-group-item border-0 ps-3">
                        <i class="fa fa-info-circle"></i> Examples: <code class="bg-success text-dark">COMPLETED</code>, <code class="bg-success text-dark">COMPLETED_MANUALLY</code>
                      </li>
                    </ul>
                  </div>

                  <div class="mb-3">
                    <h6 class="text-danger">
                      <span class="badge bg-danger me-2">3</span> Failure States
                    </h6>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item border-0 ps-3">
                        <i class="fa fa-times-circle text-danger"></i> <span class="text-danger">Red states</span> indicate failures or cancellations in the order process.
                      </li>
                      <li class="list-group-item border-0 ps-3">
                        <i class="fa fa-info-circle"></i> Examples: <code class="bg-danger text-dark">FAILED</code>, <code class="bg-danger text-dark">CANCELLED</code>
                      </li>
                    </ul>
                  </div>

                  <div class="mb-3">
                    <h6 class="text-warning">
                      <span class="badge bg-warning me-2 text-dark">4</span> Processing States
                    </h6>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item border-0 ps-3">
                        <i class="fa fa-clock-o text-warning"></i> <span class="text-warning">Orange states</span> represent intermediate processing steps.
                      </li>
                      <li class="list-group-item border-0 ps-3">
                        <i class="fa fa-info-circle"></i> Examples: <code class="bg-warning text-dark">PAYMENT_AUTHORIZATION_STARTED</code>, <code class="bg-warning text-dark">PRODUCT_ACQUISITION_STARTED</code>
                      </li>
                    </ul>
                  </div>

                  <div class="border-top pt-3 mt-2">
                    <p class="mb-0">
                      <i class="fa fa-search text-primary"></i> <strong>Note:</strong> Click on any diagram below to view a larger version with full details.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Payment Flow Diagrams Gallery -->
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header" style="background-color: #f0f0f0;">
                    <h5 class="text-dark"><i class="fa fa-sitemap"></i> Payment Flow Diagrams</h5>
                  </div>
                  <div class="card-body">
                    <div class="row my-gallery" id="aniimated-thumbnials-2" itemscope="" data-pswp-uid="1">
                      <!-- Dynamic rendering of gallery items based on gallery1 array (indices 2-5) -->
                      <div v-for="(item, index) in gallery1.slice(2, 6)" :key="index" class="col-md-6 col-lg-3 mb-4">
                        <figure class="img-hover hover-15" @click="showImg(index + 2)" itemprop="associatedMedia" itemscope="">
                          <div class="thumbnail-container">
                            <img :src="require('../../../../assets/images/ryd/' + item.image)"
                                 itemprop="thumbnail2" :alt="item.title" class="img-fluid thumbnail uniform-height">
                          </div>
                          <div class="caption mt-3">
                            <h5 class="m-l-10 m-r-10">{{ item.title }}</h5>
                            <p class="m-l-10 m-r-10">{{ item.description }}</p>
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>

        </div>

        <div class="card" id="state-machine-types-by-product">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>State Machine Types by Product/Service</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Fuelling</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Fuelling transactions can utilize either the prepay or postpay state machines, as determined by the
                petrol stations. This configuration is defined by the petrol stations themselves, not by Ryd.
              </li>
              <li class="list-group-item">
                <b>Prepay State Machine</b>: Used when customers authorize payment before fuelling. This state machine
                ensures
                the transaction amount is pre-authorized before the service is provided.
              </li>
              <li class="list-group-item">
                <b>Postpay State Machine</b>: Used when customers fuel first and pay later. This setup allows for the
                transaction amount to be finalized after the fuelling is completed.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Car Wash</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Car wash transactions exclusively use the prepay state machine:
              </li>
              <li class="list-group-item">
                <b>Prepay State Machine</b>: Customers must authorize payment before the car wash service is provided.
                This ensures that the transaction is secured prior to service delivery.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>EV Charging</h5>
            <ul class="list-group">
              <li class="list-group-item">
                EV charging transactions also utilize the prepay state machine:
              </li>
              <li class="list-group-item">
                <b>Prepay State Machine</b>:Customers are required to authorize payment before starting the charging
                session.
                This ensures that the transaction amount is pre-authorized before the service is provided, securing the
                transaction from the outset.
              </li>
              <li class="list-group-item">
                Most of the charging sessions take a long time, and the final price will be shared by <a
                  href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operators (CPOs)</a>
                anywhere from a few seconds to a few days after the completion of an EV charging session.
                This approach ensures that customers are not delayed at the charging station and can leave as soon as
                their vehicle is charged, with billing being handled asynchronously.
              </li>
            </ul>
            <br/>

          </div>


          <div class="card" id="detailed-state-descriptions">
            <div class="card-header bg-success d-flex justify-content-between align-items-center">
              <h5>Detailed State Descriptions</h5>
              <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
                <i class="icofont icofont-arrow-up"></i> Back to Top
              </button>
            </div>
            <div class="card-body">
              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>IMPORTANT: Different state types
              </h5>
              <ul class="list-group">
                <li class="list-group-item"><b>Final and not final states:</b><br/>
                  Please are aware that while an order progresses through
                  the state engine its state can reflect INTERMEDIATE states or can reach one of the FINAL states.<br/>
                  Final states are exclusively <b>COMPLETED, COMPLETED_MANUALLY, FAILED, CANCELLED</b>
                </li>
                <li class="list-group-item"><b>Helper states:</b><br/>
                  We introduced additional states acknowledging the commands from our clients to better visualize that
                  we have successfully started our asynchronous processes.<br/>
                  Those states are <b>PAYMENT_AUTHORIZATION_STARTED, PRODUCT_ACQUISITION_STARTED</b>
                </li>
                <li class="list-group-item"><b>Product specific states:</b><br/>
                  There are specific states that only appear in the state history for specific products.<br/>
                  Exclusive state for EV <b>PRODUCT_SUPPLYING</b> - this state is reflects that the charging process is
                  currently ongoing.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>CREATED</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Description:</b><br>
                  This is the very first state of the order, upon order
                  creation with the <code>POST /v4/orders</code> endpoint. When an order is in the <code>CREATED</code>
                  state, it signifies that some initial product details have been established for that order. For
                  instance, if a
                  customer is purchasing fuel, they have selected the pump number.<br><br>
                  <b>[MANUAL FLOW]</b><br>
                  The order won't remain in this state for an extended
                  period. As soon as the customer completes their interaction with the product (e.g., finishes filling
                  their vehicle's gas tank), the order will transition to the <code>PAYMENT_PENDING</code> state. At
                  this point, it is required for the API client to refrain from making an <code>/authorize</code>
                  request immediately; instead, they should wait until the order advances to the
                  <code>PAYMENT_PENDING</code> state.<br><br>
                  <b>[AUTOMATIC FLOW]</b><br>
                  If the API client does not initiate a request to
                  the <code>/authorize</code> endpoint at this stage, the order will remain in the <code>CREATED</code>
                  state. This is why it is imperative for the API client to promptly make an <code>/authorize</code>
                  request as soon as it detects the order in the <code>CREATED</code> state within the AUTOMATIC flow.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PAYMENT_PENDING</h5>
              <ul class="list-group">
                <li class="list-group-item"><b>[MANUAL FLOW]</b><br>In the MANUAL flow, the <code>PAYMENT_PENDING</code>
                  state is very important. It signifies that the customer currently possesses the product. This implies
                  that at this
                  specific moment, we possess information regarding both the price of the product and the quantity
                  obtained by the customer. This characteristic is unique to the MANUAL flow, as it enables us to
                  precisely determine the precise amount to charge the customer. You can employ the charge amount
                  obtained during this step as the authorization amount in your request to the <code>/authorize</code>
                  endpoint.<br><br>
                  <b>[AUTOMATIC FLOW]</b><br> Unlike the MANUAL flow, if the order is in the
                  <code>PAYMENT_PENDING</code> state, the customer is not in possession of the product. In this flow,
                  it's important to note that the <code>PAYMENT_PENDING</code> state does not directly concern the API
                  clients. While
                  you might see this state in the state history, its significance primarily lies within our internal
                  services.
                  Specifically, it serves as a trigger for our internal systems to decide when to initiate the
                  authorization for charging with a specific Payment Service Provider. API clients need not be directly
                  concerned with this state, as its role is primarily internal for payment processing purposes.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PAYMENT_AUTHORIZATION_STARTED [Only in
                state history]</h5>
              <ul class="list-group">
                <li class="list-group-item">When the order reaches this state, it means that the payment authorization
                  flow was triggered and initiated.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PAYMENT_AUTHORIZED</h5>
              <ul class="list-group">
                <li class="list-group-item"><b>Description:</b><br>When the order is in this state, it signifies that
                  the
                  user has given us permission to charge them a specific amount of money. At this point, the API client
                  should send a request to the <code>POST /orders/{orderId}/acquire</code> endpoint. This request
                  instructs our system to proceed with the charging process.<br><br>
                  <b>[MANUAL FLOW]</b> In the MANUAL flow, we have already obtained
                  authorization for the precise amount to be charged through the <code>POST
                    /order/{orderId}/authorize</code>
                  endpoint. We have the exact value because, as previously explained, in the MANUAL flow, the customer
                  already possesses the product when the order reaches the <code>PAYMENT_PENDING</code> state.<br><br>
                  <b>[AUTOMATIC FLOW]</b><br>In the AUTOMATIC flow, the authorized amount may
                  not
                  necessarily match the final charge to the customer, primarily because the customer has not yet
                  received the product. In this scenario, the authorized amount serves as an upper limit, indicating
                  the
                  maximum amount the customer has granted us permission to charge. However, our system ensures that
                  the
                  total charge will not surpass this maximum value. This way, the customer cannot acquire a product
                  exceeding the authorized limit.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PRODUCT_ACQUISITION_STARTED [Only in
                state history]</h5>
              <ul class="list-group">
                <li class="list-group-item">When the order reaches this state, it means that the product acquisition
                  flow
                  was triggered and initiated.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PRODUCT_PENDING</h5>
              <ul class="list-group">
                <li class="list-group-item"><b>[MANUAL FLOW]</b><br> In the MANUAL flow, this state holds no relevance
                  for
                  the
                  API client. While you might see this state in the order's state history, its significance primarily
                  lies
                  within our internal services.<br><br>
                  <b>[AUTOMATIC FLOW]</b> In the AUTOMATIC flow, the
                  <code>PRODUCT_PENDING</code> state is
                  very important. It signifies that the user must physically proceed with obtaining the product. For
                  instance, this might involve taking out a nozzle and filling the vehicle's gas tank. During this step,
                  the customer doesn't need to engage with any graphical user interfaces (GUI) or digital apps. The
                  interaction occurs solely in the physical world.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PRODUCT_SUPPLYING [Only in state history]
              </h5>
              <ul class="list-group">
                <li class="list-group-item">This sub-state is reached when the product outlet (e.g. pump, charger)
                  actually starts serving the product. In case of EV this happens when the actual charging process has
                  started. In case of fuel this state is not implemented yet but will reflect in future that the
                  customer has lifted the nozzle if the respective supply provider sends us this information.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PRODUCT_ACQUIRED</h5>
              <ul class="list-group">
                <li class="list-group-item">When the order reaches this state, it means the customer has completed all
                  necessary interactions with the app or physical product and is allowed to leave the location. In case
                  we
                  encounter any issues preventing us from charging the customer later, the customer is still permitted
                  to
                  leave, because our system has all the necessary information to address the situation later.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>READY_FOR_INVOICE</h5>
              <ul class="list-group">
                <li class="list-group-item">This state holds no relevance for the API client. It's important not to
                  build
                  your application's logic around it. Please keep in mind that its meaning and how it works may change
                  in
                  the future, or it might be removed altogether. When the order reaches this state, we start the payment
                  charge with third-party payment providers, a step often referred to as "transaction capturing." The
                  charge amount is determined by subtracting any applicable discounts from the products' value. If the
                  customer had previously authorized a higher amount than the charge, we release the difference between
                  these two values.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>COMPLETED - [FINAL STATE]</h5>
              <ul class="list-group">
                <li class="list-group-item">A stable state that marks the successful finalization of the order. The
                  money
                  has been charged and there is nothing to do anymore.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>COMPLETED_MANUALLY - [FINAL STATE]</h5>
              <ul class="list-group">
                <li class="list-group-item">This special state is specifically utilized to indicate an order that has
                  undergone manual adjustments by an administrator. Such adjustments are infrequent and are only
                  employed
                  in situations where manual intervention is necessary to rectify an order that is in a problematic
                  state.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>REJECTED</h5>
              <ul class="list-group">
                <li class="list-group-item">internal and transient state towards <code>CANCELLED</code>, money will be
                  released when
                  transitioning to <code>CANCELLED</code>.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>PROBLEM</h5>
              <ul class="list-group">
                <li class="list-group-item"><b>Description:</b><br>
                  Stable state which requires express that ryd require manual intervention as the system does not
                  know if the product has been handed over to a customer or not (money stays reserved).<br><br>
                  <b>[MANUAL FLOW]</b>: client needs to send the user into the shop to clarify
                  if
                  he paid or not!
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>CANCELLED - [FINAL STATE]</h5>
              <ul class="list-group">
                <li class="list-group-item">user or client cancelled the order.
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>FAILED [FINAL STATE]</h5>
              <ul class="list-group">
                <li class="list-group-item">for some reason (no funds, pump already reserved, ...) the purchase failed.
                  No
                  further action required. User(client) might retry by creating a new order.
                </li>
              </ul>
              <br/>

              <br/>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'sweetalert2/dist/sweetalert2.min.css';
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToRootHash, scrollToTop} from "@/utils/anchorUtils";

export default {
  name: "RydpayApisOrderState",
  keywords: ['Order State', 'State Transitions', 'State Machine', 'Order State Machine', 'State Descriptions'],
  data() {
    return {
      loggedInUserGroup: '',
      lightBoxImages: [],
      visible: false,
      gallery1: [
        {
          image: 'prepay-sequence-diagram-full.png',
          title: 'Prepay Sequence - Full',
          description: 'Complete sequence diagram showing all interactions in the prepay flow, including API client, Ryd system, and product interfaces.'
        },
        {
          image: 'postpay-sequence-diagram-full.png',
          title: 'Postpay Sequence - Full',
          description: 'Complete sequence diagram showing all interactions in the postpay flow, including API client, Ryd system, and payment authorization.'
        },
        {
          image: 'postpay-order-states.png',
          title: 'Postpay Order States',
          description: 'Diagram showing the complete state flow for Postpay transactions. In this flow, the customer receives the product before payment is finalized.'
        },
        {
          image: 'postpay-with-prepay-order-states.png',
          title: 'Postpay with Prepay',
          description: 'Hybrid flow diagram that combines elements of both prepay and postpay approaches, showing the transition states between both payment models.'
        },
        {
          image: 'prepay-order-states.png',
          title: 'Prepay Order States',
          description: 'Standard prepay state machine flow where payment authorization occurs before product delivery, used primarily for fuelling and car wash services.'
        },
        {
          image: 'prepay-ev-order-states.png',
          title: 'EV Charging States',
          description: 'Specialized prepay flow for EV charging sessions, including the PRODUCT_SUPPLYING state to track active charging progress.'
        }
      ],
    }
  },
  mounted() {
    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
    this.scrollToRootHash();
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/ryd/' + item.image))
    });
  },
  methods: {
    scrollToRootHash,
    scrollToCard,
    scrollToTop,
    showImg(index) {
      this.index = index;
      this.visible = true;
      const imageUrl = this.lightBoxImages[this.index];
      const imageTitle = this.gallery1[this.index].title || 'Image';
      const imageDescription = this.gallery1[this.index].description || '';

      this.$swal({
        imageUrl,
        imageAlt: imageTitle,
        title: imageTitle,
        text: imageDescription,
        showCloseButton: true,
        showConfirmButton: false,
        customClass: 'swal-wide',
        width: '80%'
      });
    },
  },
  watch: {
    '$route.hash': function () {
      this.scrollToRootHash();
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/swal_custom.scss";
</style>
<style scoped>
/* Enhanced gallery styles */
.my-gallery figure {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.my-gallery figure:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.thumbnail-container {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 230px; /* Fixed height for all containers */
}

.thumbnail {
  width: 100%;
  transition: transform 0.3s ease;
}

.uniform-height {
  height: 220px;
  object-fit: contain;
  max-width: 100%;
}

.img-hover:hover .thumbnail {
  transform: scale(1.05);
}

.caption {
  padding: 8px 0;
}

.caption h5 {
  margin-bottom: 8px;
  color: #4466f2;
  font-weight: 500;
}

.caption p {
  color: #6c757d;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 0;
}

/* Lightbox customization */
.swal-wide {
  max-width: 90vw !important;
  padding: 15px !important;
}

.swal-wide img {
  max-height: 85vh;
  width: auto;
  max-width: 100%;
}
</style>
